const Logo = () => {
    return (
        <svg className="logo" width="1500" height="200" version="1.1" viewBox="0 0 396.87 132.29" xmlns="http://www.w3.org/2000/svg" display="block">
          <g transform="matrix(10.43 0 0 10.445 -448.61 -531.55)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <g transform="translate(42.333 59.72)" stroke-width=".2584in">
                  <path transform="scale(.010583 -.010583)" d="m132-350 107 16 38 13-57-13-15 221-47 315-4 167 60 107 117 60 97-35 38-101-16-107-47-76-91-31-69 19-63 56-26 95-12 148v101l41 16-107.4-35"/>
                  <path transform="matrix(.010583 0 0 -.010583 5.2705 0)" d="m91.4-129h9.6l126 50.2-66-28.2-22 564 12 302 54 16-63-25h-57"/>
                  <path transform="matrix(.010583 0 0 -.010583 7.6411 0)" d="m302 472-59 13-82-28-35-66 3-92 35-60 82-41 107 35 44 79 3 94-38 51h-50"/>
                  <path transform="matrix(.010583 0 0 -.010583 12.245 0)" d="m296 81.9 35 31.1 38-18.5 6-44.1-19-53.55-66-31.45-57 9.4-50 63-7 126.2-12 299-75.8-13 169.8 22-94-9-6 192"/>
                  <path transform="matrix(.010583 0 0 -.010583 15.949 0)" d="m296 81.9 35 31.1 38-18.5 6-44.1-19-53.55-66-31.45-57 9.4-50 63-7 126.2-12 299-75.8-13 169.8 22-94-9-6 192"/>
                  <path transform="matrix(.010583 0 0 -.010583 19.653 0)" d="m126 350-16 63 29 47 63 41 82-19 44-69 12-85 3-130-15-50-63-44-67-34.7-59-9.4-47.6 28.3-3.2 69.8 24.8 44 70 18h88l25-6 38-41 12-41 4-62.7v-69.3l3-25.2 34-6.3h41l26 12.6"/>
                  <path transform="matrix(.010583 0 0 -.010583 23.749 0)" d="m44.1 810 150.9 12-72-9 35-822.45 91-28.35 94 18.9 79 75.6 28 75.3-9 82-31 60-57 38-92-19-53-44-35-54-22-56 13-176.8-47-12.6-22.5-12.6"/>
                  <path transform="matrix(.010583 0 0 -.010583 28.744 0)" d="m91.4-129h9.6l126 50.2-66-28.2-22 564 12 302 54 16-63-25h-57"/>
                  <path transform="matrix(.010583 0 0 -.010583 31.115 0)" d="m331 44.1-38-53.55-69-22.05-73 47.3-31 123.2 9 141 47 123 35 22 47-6 35-35 44-110-101-6-104-3"/>
                  <path transform="matrix(.010583 0 0 -.010583 34.777 0)" d="m129-25.2-25 34.65h-34.7l-28.3-40.95 15.7-72.5 40.9-44 75.4-10 70 38 41 57 6 100.8-51 138.2-72 155-19 119 44 86 66 18 48-15 25-32 31 35-66-104"/>
              </g>
          </g>
        </svg>
    )
}

export default Logo

